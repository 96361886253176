exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lebenslauf-tsx": () => import("./../../../src/pages/lebenslauf.tsx" /* webpackChunkName: "component---src-pages-lebenslauf-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-templates-blog-item-template-tsx": () => import("./../../../src/templates/blogItemTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-item-template-tsx" */),
  "component---src-templates-portfolio-item-template-tsx": () => import("./../../../src/templates/portfolioItemTemplate.tsx" /* webpackChunkName: "component---src-templates-portfolio-item-template-tsx" */)
}

